import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FooterContainer = styled.footer`
  background-color: #333;
  color: white;
  height: 80px;
  display: flex;
  justify-content: center;
  position: relative;
  overflow-x:hidden;
  background-color: #0d2028;
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    height: 180px;
    align-items: center;
  }
`;

const Logo = styled.img`
  width: 140px; 
  height: auto; 
  left:20px;
  position: absolute;
  top: 25px;

  @media (max-width: 768px) {
    width: 160px; 
    top: 2px;
  }
`;

const LogoContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 20px;
  width: 100%;
  position: absolute;
  z-index: 888;
  background-color: #594a4a;

  @media (max-width: 768px) {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    background-color: #0f074b;
  }
`;


const TextContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 18px;
  width: 100%;
  cursor:pointer;
  
  @media (max-width: 768px) {
    width: 40%;
    font-size: 22px;
    order: 2;
    margin-top: -60px;
    margin-left: auto;
    margin-right: auto;
  }

  span{

    &:hover{
        text-decoration: underline;
      }
    a{
      text-decoration: none;
    }
  }
`;


const Footer = () => {
  return (
    <FooterContainer>
    <LogoContainer>
       <Logo src="/img/First.svg" alt="Company Logo" />
    </LogoContainer>
     

   
      <TextContainer>
      <span>Inquiry@first.dev</span>  &nbsp;&nbsp; | &nbsp;&nbsp; <span><Link style={{color:'white',}} to="/terms">Terms & Conditions</Link></span>  &nbsp;&nbsp; | &nbsp;&nbsp; <span><Link style={{color:'white',}} to="/policy">Privacy Policy</Link></span>&nbsp;&nbsp; | &nbsp;&nbsp;<span>Gamma Sports Group</span>  &nbsp;&nbsp; | &nbsp;&nbsp; <span>Address:str. Filip</span>  &nbsp;&nbsp; | &nbsp;&nbsp; <span>Kutev,Sofia Bulgaria</span>  &nbsp;&nbsp; | &nbsp;&nbsp; <span>UIC:205466228</span>  &nbsp;&nbsp;
      </TextContainer>
     
      
    </FooterContainer>
  );
};

export default Footer;
